import React from 'react'
import "./Navbar.css";
import {Link} from "react-router-dom";
import Logo from "../images/logo.jpeg";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid ">
    <Link to="/"> 
    <img src={Logo} alt='logo' className='me-2' height="40" width="100"/>
    </Link>
    <Link to="/Menupage">
    <p style={{color:"maroon"}}> <small>RANNABATI MENU</small></p>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" style={{color:"maroon"}} className="nav-link active" aria-current="page" href="#">Home</Link>
        </li>
  
        
  
        <li className="nav-item">
          <Link to="/Aboutus" style={{color:"maroon"}} className="nav-link " aria-current="page" href="#">About Us</Link>
        </li>

        <li className="nav-item dropdown">
<a className="nav-link dropdown-toggle" style={{color:"maroon"}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
  Room
</a>
<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
<li><Link to="/Premium-deluxe" className="dropdown-item" href="#">Premium Deluxe</Link></li>
  <li><Link to="/Deluxe" className="dropdown-item" href="#">Deluxe</Link></li>

  <li><hr className="dropdown-divider"/></li>
  <li><Link to="/single-ac" className="dropdown-item" href="#"> Single AC</Link></li>
</ul>
</li>
        <li className="nav-item">
          <Link to="/Banquet" style={{color:"maroon"}} className="nav-link " aria-current="page" >Banquet</Link>
        </li>
        <li className="nav-item">
          <Link to="/Restaurant" style={{color:"maroon"}} className="nav-link " aria-current="page" >Restaurant</Link>
        </li>
        <li className="nav-item">
          <Link to="/Contact-us" style={{color:"maroon"}} className="nav-link " href="#" tabindex="-1" aria-disabled="true">Contact</Link>
        </li>
      </ul>
        <a href='tel: +91  9609612912'>
        <button className="check" type="submit">Book Now</button>
        </a>
    
    </div>
  </div>
</nav>
</div>
       </div>
  )
}

export default Navbar
import React from 'react'
import Breakfast from "../images/breakf.jpg"
import Breakfasts from './Breakfasts';
import Break from "../images/brektime.jpg";
import Breakimage from "../images/break-image.jpg"
import Tandoor from './Tandoor';
import Bangaliana from './Bangaliana';
import Thali from './Thali';
import specialthali from "../images/thaliphoto.jpg";
import khabarebangali from "../images/khanre bangali.jpg";
import Chanieese from "../images/chineese.jpg";
import indianspecial from "../images/indianspecial.jpg";
import Bangali from "../images/bangali.jpg";
import tandoor from "../images/tandoori.jpg";
import Thalis from "../images/thalis.jpg"
import Tandoors from "../images/tandoor roti.jpg";
import Chinese from './Chinese';
import Chowmin from "../images/chow.jpg"
import Indianspecial from './Indianspecial';
import Rice from "../images/indian-special-krishnagar.jpg"
function Menu() {
    return (
        <div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">

                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="card mb-3 me-2">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <a href='#breakfast'>
                                                <img src={Breakfast} className="img-fluid rounded-start" alt="Ranna_Bati" />
                                            </a>
                                        </div>
                                        <div className="col-md-8">
                                            <a href='#breakfast'>
                                                <div className="card-body">
                                                    <h6 style={{ color: "grey" }} className="card-title ">BREAK FAST</h6>
                                                    <p className="card-text"> <small>(7:30 A.M to 10 A.M)</small></p>

                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="card mb-3 me-2">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <a href='#bangaliana'>
                                                <img src={khabarebangali} className="img-fluid rounded-start" alt="Ranna_Bati" />
                                            </a>
                                        </div>
                                        <div className="col-md-8 ">
                                            <div className="card-body ">
                                                <a href='#bangaliana'>
                                                    <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Khabare Bangaliana</small></h6>
                                                    <p className="card-text"> <small> ছোট বাটি & বড় বাটি </small></p>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-6 mt-2 ">

                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="card mb-3 me-2">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={Chanieese} className="img-fluid rounded-start" alt="Ranna_Bati" />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h6 style={{ color: "grey" }} className="card-title ">Chinese</h6>
                                    

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3 me-2 mt-2">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <img src={indianspecial} className="img-fluid rounded-start" alt="Ranna_Bati" />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body ">
                                                <h6 style={{ color: "grey" }} className="card-title mb-0">Indian Special </h6>
                                              

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

<div className="col-md-6 mt-2">

<div className="d-flex justify-content-between align-items-center mb-2">
    <div className="card mb-3 me-2">
        <div className="row g-0">
            <div className="col-md-4">
                <a href='#chinese'>
                    <img src={Chanieese} className="img-fluid rounded-start" alt="Ranna_Bati" />
                </a>
            </div>
            <div className="col-md-8">
                <a href='#chinese'>
                    <div className="card-body">
                        <h6 style={{ color: "grey" }} className="card-title mb-0">Chaniese</h6>
                        <p className="card-text"> <small> Chowmin / Rice </small></p>
              

                    </div>
                </a>
            </div>
        </div>
    </div>


    <div className="card mb-3 me-2">
        <div className="row g-0">
            <div className="col-md-4">
                <a href='#indianspecial'>
                    <img src={indianspecial} className="img-fluid rounded-start" alt="Ranna_Bati" />
                </a>
            </div>
            <div className="col-md-8 ">
                <div className="card-body ">
                    <a href='#indianspecial'>
                        <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Indian Special</small></h6>
                        <p className="card-text"> <small>  Rice / Roti </small></p>

                    </a>

                </div>
            </div>
        </div>
    </div>
</div>
</div>
                        <div className="col-md-6 mt-2">

                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="card mb-3 me-2">
                                    <div className="row g-0">


                                        <div className="col-md-4">
                                            <a href='#tandoor'>
                                                <img src={Tandoors} className="img-fluid rounded-start" alt="rannabati" />
                                            </a>
                                        </div>
                                        <div className="col-md-8">
                                            <a href='#tandoor'>
                                                <div className="card-body">
                                                    <h6 style={{ color: "grey" }} className="card-title mb-0">Tandoor Special</h6>
                                                    <p className="card-text mb-0"> <small>(14 type)</small></p>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="card mb-3 me-2">
                                    <div className="row g-0">
                                        <div className="col-md-4">
                                            <a href='#thali'>
                                                <img src={specialthali} className="img-fluid rounded-start" alt="rannabati" />
                                            </a>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body ">
                                                <a href='#thali'>

                                                    <h6 style={{ color: "grey" }} className="card-title mb-0">Thali</h6>
                                                    <p className="card-text"> <small> স্পেশাল থালি </small></p>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   

                    </div>
                </div>
            </div>

            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">
                            <Breakfasts />
                        </div>
                        <div className="col-md-6 mt-3">
                            <br />
                            <br />

                            <div className='krishnagarrestro'>
                                <img src={Break} className="card-img-top mb-3" alt="rannbati" />
                                <img src={Breakimage} className="card-img-top" alt="rannbati" />
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">
                            <Tandoor />
                        </div>
                        <div className="col-md-6 mt-3">
                            <br />
                            <br />

                            <div className='krishnagarrestro'>
                                <img src={tandoor} className="card-img-top mb-3" alt="rannbati" />

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">
                            <Bangaliana />
                        </div>
                        <div className="col-md-6 mt-3">
                            <br />
                            <br />

                            <div className='krishnagarrestro'>
                                <img src={Bangali} className="card-img-top mb-3" alt="rannbati" />

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">
                            <Chinese/>
                        </div>
                        <div className="col-md-6 mt-3">
                           
                            <div className='krishnagarrestro'>
                                <img src={Chowmin} className="card-img-top mb-3" alt="rannbati" />

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row' >
                        <div className="col-md-6 mt-2">
                            <Indianspecial/>
                        </div>
                        <div className="col-md-6 mt-3">
                           
                            <div className='krishnagarrestro'>
                                <img src={Rice} className="card-img-top mb-3" alt="rannbati" />

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-6 mt-2">
                            <Thali />
                        </div>

                        <div className="col-md-6 mt-3">


                            <div className='krishnagarrestro'>
                                <img src={Thalis} className="card-img-top " alt="rannbati" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default Menu
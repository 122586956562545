import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import Banquethall from '../inc/Banquethall';
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
function Banquet() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      
      <ScrollToTop smooth top="100" color="maroon" />
      <div className="krishnagarrestaurant">
                <div className="album py-1">

                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Banquet Hall</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
      <div className='album py-2'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-6 mt-2">



              <h3 className="card-title">Rannabati Banquet Explore</h3>
              <p className="card-text"> Any party, Birthday, Meeting, Seminer, Event Booking Available. It has a seating capacity of 30 to 40 people.</p>
              <div className="d-flex justify-content-between align-items-center mb-2">

                <a  className="viewmenu" href='tel: 9609612912'> Book Banquet </a>
                <ReactWhatsapp number="+91 9609612912" className="btn btn-success" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>



              </div>


            </div>
            <div className="col-md-6 mt-2">
              <div className='krishnagarrestro'>
                <Banquethall />
                {/* <img src={Menuimage} className="card-img-top" alt="best restaurant in krishnagar" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  )
}

export default Banquet
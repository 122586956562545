import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Menuimage from "../images/food.jpg"
import "./Home.css";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import Menu from './Menu';
import premiumDeluxe from "../images/deluxe-premium.jpeg"
import Deluxeroom from "../images/rannabati-room.jpg"
import Singleac from "../images/singleac.jpg";
import guest from "../images/rannabati guest.jpeg";
import guestrannabati from "../images/our guest.jpeg"

function Home() {
    return (
        <div>
            <Navbar />
            <br />
            <br />

            <br />
            <div style={{ color: "Green" }} className="fixed-bottom "> <ReactWhatsapp number="+91  9609612912" className="whatsappbutton" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm" role="status">
                    <span className="visually-hidden"> Loading...</span>
                </div></ReactWhatsapp></div>
            <div className='album py-0'>
                <div className='container'>
                    <Slider />
                </div>
            </div>
            <br />
            <ScrollToTop smooth top="100" color="maroon" />
            <div className='album py-1'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-6 mt-2">



                            <h3 className="card-title">Rannabati Explore</h3>
                            <p className="card-text mb-0">RANNABATI Special Menu Available Online Official Website</p>
                            <p><small>Best hotel and restaurant at Krishnanagar</small></p>
                            <div className="d-flex justify-content-between align-items-center mb-2">

                                <Link to="/Menupage" className="viewmenu">Click Menu</Link>
                                <Link to="/Premium-deluxe" className="viewmenu">Room Book</Link>
                                <Link to="/Restaurant" className="viewmenu">Restaurant</Link>

                            </div>


                        </div>
                        <div className="col-md-6 mt-2">
                            <div className='krishnagarrestro'>
                                <img src={Menuimage} className="card-img-top" alt="best restaurant in krishnagar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-2'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-4 mt-2">
                            <div className="card" >
                                <img src={premiumDeluxe} className="card-img-top" alt="premiumDeluxe" />
                                <div className="card-body">
                                    <div className='textcolor'>
                                        <h4 className="card-title mb-2">Premium Deluxe</h4>
                                    </div>
                                    <p className="card-text mb-1" >Breakfast Complementary</p>
                                    <h6 style={{ color: "grey" }} className="card-text mb-3">Room Tariff: Rs. 2,100/-</h6>
                                    <a href="Premium-deluxe" className="homebutton">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-2">
                            <div className="card" >
                                <img src={Singleac} className="card-img-top" alt="Deluxeroom" />
                                <div className="card-body">
                                    <div className='textcolor'>
                                        <h4 className="card-title">Deluxe</h4>
                                    </div>
                                    <p className="card-text mb-1">Breakfast Complementary</p>
                                    <h6 style={{ color: "grey" }} className="card-text mb-3">Room Tariff: Rs. 1,600/-</h6>
                                    <a href="Deluxe" className="homebutton">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-2">
                            <div className="card" >
                                <img src={Deluxeroom} className="card-img-top" alt="Singleac" />
                                <div className="card-body">
                                    <div className='textcolor'>
                                        <h4 className="card-title">Single AC</h4>
                                    </div>
                                    <p className="card-text mb-1">Breakfast Complementary</p>
                                    <h6 style={{ color: "grey" }} className="card-text mb-3">Room Tariff: Rs. 900/- (More Details)</h6>

                                    <a href="single-ac" className="homebutton">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Menu />
            <br></br>
            <div className='colorg'>
                <br/>
                <br/>
            <h2 style={{ color: "Darkblue" }}> <center>OUR GUEST</center></h2>
            <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="10000">
                        <div className="px-4 py-5 my-12 text-center">
                            <div className='radiusb'>
                                <center>

                                    <img src={guest} className="mb-2" height="200px" width="250px" alt='rannabati' />
                                </center>
                            </div>
            
                            {/* <p className="btn btn btn-lg px-4 gap-3">Dr. Soumen Chatterjee (MBBS)</p> */}
                        </div>

                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="px-4 py-5 my-12 text-center">
                            <div className='radiusb'>
                                <center>

                                    <img src={guestrannabati} className="mb-2" height="330px" width="250px" alt='rannabati' />
                                </center>
                            </div>
                   
                           
                        </div>

                    </div>
                 
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
           
            <br />
            </div>

        </div>
    )
}

export default Home
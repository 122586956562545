
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDbAUmMhRlveOT4eutkakykv3h6jAYQqfU",
    authDomain: "ranna-bati-43136.firebaseapp.com",
    projectId: "ranna-bati-43136",
    storageBucket: "ranna-bati-43136.appspot.com",
    messagingSenderId: "949247061789",
    appId: "1:949247061789:web:37cae6fdecadc1dd35bbf4",
    measurementId: "G-MM3YF42EW7"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;
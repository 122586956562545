import React from 'react'
import Navbar from '../inc/Navbar'
import Menu from './Menu'
import ScrollToTop from "react-scroll-to-top";
function Menupage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon"/>
        <Menu/>
       
    </div>
  )
}

export default Menupage
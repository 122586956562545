import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";
import Rannbatihsgropuabout from "../images/rannabati-hsgroup.jpg"

function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
        
            <div className="krishnagarrestaurant">
                <div className="album py-1">

                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
            <center>
                <h1 style={{color:'grey'}}>About Us</h1></center>
            <div className='album py-0'>
                <div className='container'>
                    <hr />
                </div>
            </div>
            <div className='album py-1'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-6 mt-2">
                            <h5 className='texts'>Rannabati was established in 2016, RN Tagore Road, Krishnagar. Our mission is to provide best quality food, hygienic, pleasant environment, respect to people of all walks of life and their happiness.</h5>
                        </div>
                        <div className="col-md-6 mt-2">

                            <img src={Rannbatihsgropuabout} className="card-img-top" alt="Rannbatihsgropuabout" />


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br/>
        </div>
    )
}

export default Aboutus
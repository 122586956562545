import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'


import ReactWhatsapp from "react-whatsapp";




function Premium() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [premiumdeluxe, setPremiumdeluxe] = useState([])
    const navigate = useNavigate()

    const premiumdeluxeCollectionRef = collection(db, "premiumdeluxe");
    useEffect(() => {

        const getPremiumdeluxe = async () => {
            const data = await getDocs(premiumdeluxeCollectionRef);
            setPremiumdeluxe(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getPremiumdeluxe()
    }, [])
    return (

        <>




            <br />

            <section className="section featured-car" id="deluxeroom">

                <div className="album py-2">

                    <div className="container">

                        <div className="row">
                            {premiumdeluxe.filter((val) => {
                                if (setsearchTerm === "") {
                                    return val;
                                } {
                                    return val;
                                }
                            }).slice(pagination.start, pagination.end).map((rannabati) => {
                                return (
                                    <div className="col-md-4 mt-8" >
                                        <div className=" mb-4">
                                            <div className='krishnagarrestro'>
                                                <img className="card-img-top" src={rannabati.img} alt="Premium Deluxe" />
                                            </div>
                                                
                                        </div>

                                    </div>
                                )
                            })}
                        </div>



                    </div>
                </div>
            </section>

        </>

    );
}

export default Premium;




import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Restaurant from './components/pages/Restaurant';
import Contactus from './components/pages/Contact.us';
import Premiumdeluxe from './components/pages/Premiumdeluxe';
import Menupage from './components/pages/Menupage';
import Deluxe from './components/pages/Deluxe';
import Banquet from './components/pages/Banquet';
import Singleac from './components/pages/Singleac';
import Aboutus from './components/pages/Aboutus';

function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Restaurant" element={<Restaurant/>}/>
      <Route axact path="/Contact-us" element={<Contactus/>}/>
      <Route axact path="/Premium-deluxe" element={<Premiumdeluxe/>}/>
      <Route axact path="/Menupage" element={<Menupage/>}/>
      <Route axact path="/Deluxe" element={<Deluxe/>}/>
      <Route axact path="/Banquet" element={<Banquet/>}/>
      <Route axact path="/single-ac" element={<Singleac/>}/>
      <Route axact path="/Aboutus" element={<Aboutus/>}/>
        
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;

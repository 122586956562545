import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'




function Ambience() {
    const [setShow] = useState(false);


    const [showPerPage, setShowPerPage] = useState(21);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const [searchTerm, setsearchTerm] = useState("");
    const [ambience, setAmbience] = useState([])

    const ambienceCollectionRef = collection(db, "Ambience");
    useEffect(() => {

        const getAmbience = async () => {
            const data = await getDocs(ambienceCollectionRef);
            setAmbience(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAmbience()
    }, [])
    return (

        <>




            <section className="section featured-car" id="deluxeroom">

                <div className="album py-1">

                    <div className="container">

                        <div className="row">
                            {ambience.filter((val) => {
                                if (setsearchTerm === "") {
                                    return val;
                                } {
                                    return val;
                                }
                            }).slice(pagination.start, pagination.end).map((sghjewellers) => {
                                return (
                                    <div className="col-md-3 mt-3" >
                                        <div className=" mb-4">
                                            <div className='krishnagarrestro'>
                                                <img className="card-img-top" src={sghjewellers.img} alt="Premium Deluxe" />
                                            </div>

                                        </div>

                                    </div>
                                )
                            })}
                        </div>



                    </div>
                </div>
            </section>

        </>

    );
}

export default Ambience;




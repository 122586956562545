import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
// import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
// import "./Menu.css";
import ReactWhatsapp from "react-whatsapp";
// import { Typewriter } from 'react-simple-typewriter' 
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";



function Breakfasts() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(51);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [breakfast, setBreakfast] = useState([])
  const navigate = useNavigate()

  const breakfastCollectionRef = collection(db, "Breakfast");
  useEffect(() => {

    const getBreakfast = async () => {
      const data = await getDocs(breakfastCollectionRef);
      setBreakfast(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getBreakfast()
  }, [])
  return (

    <>
      {/* <Navbar/> */}
      <div className="luxuryhotelberhampore">


        <h1 style={{ color: "grey" }} ><b><center>Our Menu</center></b></h1>

        {/* <h2 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Bala", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2> */}
        <section className="section featured-car" id="breakfast">
          <div className="album py-2">

            <div className="container">

              <div className="row">
                {breakfast.filter((val) => {
                  if (setsearchTerm === "") {
                    return val;
                  } else if (
                    val.p.toLowerCase().includes(searchTerm.toLowerCase())


                  ) {
                    return val;
                  }

                }).slice(pagination.start, pagination.end).map((rannabati) => {
                  return (
                    <div className="col-md-12">
                      <div className="card mb-4 border-dark box shadow">
                        <div className="imagesc">

                          <div style={{ color: "Darkblue" }} className="card-header mb-0"><b><center>  {rannabati.name} <p>(07:00 A.M TO 10 A.M)</p></center> </b>
                          
                          </div>

                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-2">


                              <h6 className="card-text mb-1"> <p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p}</small></p></h6>



                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price} </h6>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-1"> <p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p2}</small></p></h6>


                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price2} </h6>

                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title3}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p3}</small></p></h6>


                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price3} </h6>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title4}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p4}</small></p></h6>


                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price4} </h6>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title5}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p5}</small></p></h6>


                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price5} </h6>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title6}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p6}</small></p></h6>


                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price6} </h6>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title7}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p7}</small></p></h6>
                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price7} </h6>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title8}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p8}</small></p></h6>
                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price8} </h6>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <h6 className="card-text mb-2"> <b>{rannabati.title9}</b><p style={{ color: "maroon" }} className="card-text mb-2"> <small> {rannabati.p9}</small></p></h6>
                              < h6 style={{ color: "maroon" }} className="card-text"> ₹{rannabati.price9} </h6>
                            </div>

       

                            

                            


                            


                            




                            <div className="d-flex justify-content-between align-items-center mb-2">

                              <a href="tel: +91 9609612912" className="checkmenu">Call Book </a>
                              <ReactWhatsapp number="+91 9609612912" class="checkmenu" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                              </svg>  Whatsapp</ReactWhatsapp>
                              <button type="button" className="checkmenu" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                </svg> Share
              </button>
              </div>
            </div>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <center>
                      < FacebookShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <FacebookIcon size={37} round={true} />
                      </FacebookShareButton>

                      <  WhatsappShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <WhatsappIcon size={37} round={true} />
                      </ WhatsappShareButton>

                      <  TwitterShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <TwitterIcon size={37} round={true} />
                      </ TwitterShareButton>

                      <  LinkedinShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <  LinkedinIcon size={37} round={true} />
                      </ LinkedinShareButton>

                      <  EmailShareButton url="https://www.rannabarihsgroup.com/Restaurant" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <  EmailIcon size={37} round={true} />
                      </ EmailShareButton>
                    </center>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>

                  </div>
                </div>
           

           
                            </div>
                           

                          </div>
                        </div>
                      </div>

                    </div>

                  )
                })}
              </div>
              {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={room.length}
                /> */}


            </div>
          </div>
        </section>
      </div>

    </>

  );
}

export default Breakfasts;




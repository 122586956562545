import React from 'react'
import Navbar from '../inc/Navbar'
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Foods from './Foods';
import Ambience from './Ambience';
import Menu from './Menu';
import Rannabatimaingate from "../images/RANNABATI-RESTRO.jpg";
import ScrollToTop from "react-scroll-to-top";
function Restaurant() {

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="maroon"/>
      <div className='album py-2'>
                <div className='container'>
                  <div className='krishnagarrestro'>

                <img src={Rannabatimaingate} className="d-block w-100" alt="Rannabati"/>
                </div>
                  </div>
                  </div>
      <div className='album py-2'>
        <div className='container'>

          <h2>RANNABATI</h2>
          <h6 style={{ color: "grey" }}>64,R.N Tagore Road, High Street, Krishnanagar, Pin-741101, Dist- Nadia</h6>
          <p style={{ color: "grey" }}>Time: 8am – 11pm (Everyday) </p>
          {/* <div className="row"> */}
          <div className="col-md-6">
            <div className="d-flex  align-items-start mb-4">
              <a href='https://maps.app.goo.gl/gyTT4RmXN9WsZ8R3A' target="_blank" rel="noopener noreferrer" >
                <button className='booknow me-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sign-merge-left" viewBox="0 0 16 16">
                  <path d="M7.25 6v1q-.211.451-.588.95c-.537.716-1.259 1.44-2.016 2.196l.708.708.015-.016c.652-.652 1.33-1.33 1.881-2.015V12h1.5V6h1.216a.25.25 0 0 0 .192-.41L8.192 3.23a.25.25 0 0 0-.384 0L5.842 5.59a.25.25 0 0 0 .192.41z" />
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 0 1 .7 0l6.516 6.515a.495.495 0 0 1 0 .7L8.35 14.866a.495.495 0 0 1-.7 0L1.134 8.35a.495.495 0 0 1 0-.7L7.65 1.134Z" />
                </svg> Direction</button></a>

              <button type="button" className="booknow" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                </svg> Share
              </button>
              </div>
            </div>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <center>
                      < FacebookShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <FacebookIcon size={37} round={true} />
                      </FacebookShareButton>

                      <  WhatsappShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <WhatsappIcon size={37} round={true} />
                      </ WhatsappShareButton>

                      <  TwitterShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <TwitterIcon size={37} round={true} />
                      </ TwitterShareButton>

                      <  LinkedinShareButton url="https://www.rannabarihsgroup.com/Restaurant" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <  LinkedinIcon size={37} round={true} />
                      </ LinkedinShareButton>

                      <  EmailShareButton url="https://www.rannabarihsgroup.com/Restaurant" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                      >
                        <  EmailIcon size={37} round={true} />
                      </ EmailShareButton>
                    </center>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>

            </div>



            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Food (4)</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Ambience (5)</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Menu</button>
              </li>
            
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <br />
                <Foods />
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br/>
                <Ambience/>
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <br/>
                <Menu/>
              </div>
             
            </div>
          

        </div>
      </div>
    </div>
  )
}

export default Restaurant